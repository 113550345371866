import { map, sumBy, filter } from 'lodash';
import React from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { ListItem, ListItemText } from '@material-ui/core'
import { PulseLoader } from 'react-spinners';


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingBottom: 55
  },
  invoices: {
    flexDirection: 'column',
    flexGrow: 1
  },
  headerRow: {
    marginLeft: 20,
    marginRight: 20,
    flexDirection: 'row',
    borderTop: 1,
    borderRight: 1,
    borderBottom: 1
  },
  tableRow: {
    marginLeft: 20,
    marginRight: 20,
    flexDirection: 'row',
    borderRight: 1,
  },
  eCell: {
    borderLeft: 0
  },
  dCell: {
    borderLeft: 1,
    borderTop: 1
  },
  headerCell: {
    borderLeft: 1
  },
  section: {
    flexDirection: 'column',
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  cell:{
    marginLeft: 5,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 10,
    textAlign: 'center'
  },
  title: {
      marginTop: 20,
      marginLeft: 450,
      fontSize: 20,
      fontWeight: 1000,
  },
  footer: {
      marginBottom: 10
  },
  date: {
      flexDirection: 'column',
      marginLeft: 285,
      marginTop: 15,
      width: 115,
      textAlign: 'center'
  },
  customer: {
      flexDirection: 'column',
      marginLeft: 20,
      width: 300,
      marginBottom: 15
  },
  totalRow: {
      borderBottom: 1,
      borderRight: 1,
      width: 572,
      marginLeft: 20,
      flexDirection: 'row',
      fontSize: 11,
      textAlign: 'center'
  },
  totalCell: {
      borderLeft: 1,
      width: 95,
      padding: 3,
  }
});

// Create Document Component
const MyDocument = ({list, customer}) => {
    const today = new Date().toLocaleDateString();
    const getBalances = map(list, async (item, index) => {
        const runningTotal = index === 0 ? 0 : Number(list[index -1][10])
        await item.push((Number(item[9]) + runningTotal).toFixed(2));
        return item;
    })
    const format = num => (Number(num).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))
    const total = format(sumBy(list, i => (Number(i[9]))));
    const current = format(sumBy(filter(list, i => (Number(i[8]) < 31)), i => (Number(i[9]))));
    const thirty = format(sumBy(filter(list, i => (Number(i[8]) > 30 && Number(i[8]) < 61)), i => (Number(i[9]))));
    const sixty = format(sumBy(filter(list, i => (Number(i[8]) > 60 && Number(i[8]) < 91)), i => (Number(i[9]))));
    const ninety = format(sumBy(filter(list, i => (Number(i[8]) > 90 && Number(i[8]) < 121)), i => (Number(i[9]))));
    const old = format(sumBy(filter(list, i => (Number(i[8]) > 120)), i => (Number(i[9]))));
  return (
  <Document>
    <Page size="LETTER" style={styles.page}>
        <Text render={({ pageNumber, totalPages }) => (
                `Page ${pageNumber} of ${totalPages}`
            )}
            fixed
            style={{ fontSize: 9, position: 'absolute', bottom: 5, right: 15 }}
        />
        <View style={styles.header} fixed>
            <Text style={styles.title}>STATEMENT</Text>
            <View style={{ flexDirection: 'row', marginBottom: 20 }} fixed>
                <View style={{ flexDirection: 'column', marginTop: -10, marginLeft: 20 }} fixed>
                    <Text fixed>Gulf-Pro Services</Text>
                    <Text fixed>P.O. Box 228</Text>
                    <Text fixed>Houma, LA 70361</Text>
                </View>
                <View style={styles.date}>
                    <Text style={{ border: 1, fontSize: 12, paddingTop: 2, paddingBottom: 2 }}>Date</Text>
                    <View style={{ border: 1, fontSize: 11, height: 20, paddingTop: 2, paddingBottom: 2  }}>
                        <Text>{today}</Text>
                    </View>
            </View>
            </View>
            <View style={styles.customer} fixed={false} >
                <Text style={{ border: 1, paddingLeft: 15, fontSize: 12, paddingTop: 2, paddingBottom: 2 }}>To</Text>
                <View style={{ border: 1, paddingLeft: 15, fontSize: 11, height: 90, paddingTop: 2, paddingBottom: 2  }}>
                    <Text>{customer}</Text>
                </View>
            </View>
        </View>
      <View style={styles.invoices} >
        <View style={{ ...styles.tableRow, borderColor: 'white', marginTop: -15}} >
          <View style={{ ...styles.eCell, width: 120 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.eCell, width: 150 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.eCell, width: 75 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.dCell, width: 110 }}><Text style={styles.cell}>Amount Due</Text></View>
          <View style={{ ...styles.dCell, width: 117, borderRight: 1 }}><Text style={styles.cell}>Amount Enc.</Text></View>
        </View>
        <View style={{ ...styles.tableRow, borderColor: 'white'}} >
          <View style={{ ...styles.eCell, width: 120 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.eCell, width: 150 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.eCell, width: 75 }}><Text style={styles.cell}> </Text></View>
          <View style={{ ...styles.dCell, width: 110 }}><Text style={styles.cell}>{total}</Text></View>
          <View style={{ ...styles.dCell, width: 117, borderRight: 1 }}><Text style={styles.cell}> </Text></View>
        </View>
        <View style={styles.headerRow} fixed>
          <View style={{ ...styles.headerCell, width: 120 }}><Text style={styles.cell}>Date</Text></View>
          <View style={{ ...styles.headerCell, width: 150 }}><Text style={styles.cell}>Invoice</Text></View>
          <View style={{ ...styles.headerCell, width: 75 }}><Text style={styles.cell}>Aging</Text></View>
          <View style={{ ...styles.headerCell, width: 110 }}><Text style={styles.cell}>Amount</Text></View>
          <View style={{ ...styles.headerCell, width: 118 }}><Text style={styles.cell}>Balance</Text></View>
        </View>
        {map(list, (item, index) => (
            <View style={styles.tableRow} key={item[3]} break={index > 0 && Number.isInteger(index / 25)}>
                <View style={{ ...styles.headerCell, width: 120 }}><Text style={styles.cell}>{item[2]}</Text></View>
                <View style={{ ...styles.headerCell, width: 150 }}><Text style={styles.cell}>{item[3]}</Text></View>
                <View style={{ ...styles.headerCell, width: 75 }}><Text style={styles.cell}>{item[8]}</Text></View>
                <View style={{ ...styles.headerCell, width: 110 }}><Text style={styles.cell}>{item[9].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text></View>
                <View style={{ ...styles.headerCell, width: 118 }}><Text style={styles.cell}>{item[10].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text></View>
            </View>
        ))}
        <View style={{ ...styles.tableRow, borderBottom: 1, flexGrow: 1 }} fixed>
            <View style={{ ...styles.headerCell, width: 120 }}><Text style={styles.cell}> </Text></View>
            <View style={{ ...styles.headerCell, width: 150 }}><Text style={styles.cell}> </Text></View>
            <View style={{ ...styles.headerCell, width: 75 }}><Text style={styles.cell}> </Text></View>
            <View style={{ ...styles.headerCell, width: 110 }}><Text style={styles.cell}> </Text></View>
            <View style={{ ...styles.headerCell, width: 118 }}><Text style={styles.cell}> </Text></View>
        </View>
        </View>
        <View style={styles.totalRow}>
            <View style={styles.totalCell}><Text>Current</Text></View>
            <View style={styles.totalCell}><Text>1-30 Days Past Due</Text></View>
            <View style={styles.totalCell}><Text>31-60 Days Past Due</Text></View>
            <View style={styles.totalCell}><Text>61-90 Days Past Due</Text></View>
            <View style={styles.totalCell}><Text>Over 90 Days Past Due</Text></View>
            <View style={styles.totalCell}><Text>Amount Due</Text></View>
        </View>
        <View style={styles.totalRow}>
            <View style={styles.totalCell}><Text>{current}</Text></View>
            <View style={styles.totalCell}><Text>{thirty}</Text></View>
            <View style={styles.totalCell}><Text>{sixty}</Text></View>
            <View style={styles.totalCell}><Text>{ninety}</Text></View>
            <View style={styles.totalCell}><Text>{old}</Text></View>
            <View style={styles.totalCell}><Text>{total}</Text></View>
        </View>
    </Page>
  </Document>
)};

const Item = props => {
  const today = new Date().toLocaleDateString()
  const { customer, list } = props;
  const total = sumBy(list, i => (Number(i[9]))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return customer ? 
  <PDFDownloadLink document={<MyDocument {...props} />} fileName={`${customer.replace(/\./g, '')} ${today}`}>
    {({ blob, url, loading, error }) => (
        loading ?
            <PulseLoader color={"#61dafb"} />
            :
            <ListItem button>
              <ListItemText
                primary={customer}
                secondary={total}
              />
            </ListItem>
    )}
  </PDFDownloadLink>
  : null
}


export default Item;

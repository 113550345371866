import _ from 'lodash';
import React, { useState }  from 'react';
import 'App.css';
import PapaParse from 'papaparse'
import { List, TextField, InputAdornment } from '@material-ui/core'
import MyDocument from 'components/statement'
import { makeStyles } from '@material-ui/core/styles';
import { RotateLoader } from 'react-spinners';

const useStyles = makeStyles(theme => ({
  root: {
    width: 24,
    margin: 'auto',
    marginTop: theme.spacing(2)
  },
  input: {
    width: 240,
    margin: 'auto'
  }
}));

const CSVReader = ({
  accept = '.csv, text/csv',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  fileEncoding = 'UTF-8',
  inputId = null,
  inputStyle = {},
  label,
  onError,
  onFileLoaded,
  parserOptions = {},
  onStart,
}) => {
  const handleChangeFile = e => {
    let reader = new FileReader();
    if (e.target.files.length > 0) {
      const filename = e.target.files[0].name;
      onStart();
      reader.onload = event => {
          
        const csvData = PapaParse.parse(
          event.target.result,
          Object.assign(parserOptions, {
            error: onError,
            encoding: fileEncoding,
          }),
        );
        onFileLoaded(csvData.data, filename);
      };

      reader.readAsText(e.target.files[0], fileEncoding);
    }
  };

  return (
    <div className={cssClass}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={e => handleChangeFile(e)}
      />
    </div>
  );
};

const Aging = () => {
    const classes = useStyles();
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [amount, setAmount] = useState(0);
    const evaluate = value => {
      if (isNaN(value)) return null;
      return setAmount(value);
    };
    const loadData = async data => {
        const filtered = _.sortBy(_.remove(data, item => _.includes(item, "Invoice")), o => (o[5]));
        const amountFilter = await _.remove(filtered,o => (parseFloat(o[9])>=amount));
        const customerList = _.map(_.uniqBy(amountFilter, o=>(o[5])), customer=>(customer[5]));
        await setCustomers(customerList);
        await setInvoices(amountFilter);
        return setLoading(false);
    };
    return (
        <div>
            <h3 style={{ textAlign: 'center' }}>Click or Drop Files below to generate statements</h3>
            <h5 style={{ textAlign: 'center' }}><a href="https://youtu.be/lPj0U6s3s-w">Click Here for Instructional Video</a></h5>
            <CSVReader
                onFileLoaded={data => loadData(data)}
                onStart={() => setLoading(true)}
                cssClass="upload"
                cssInputClass="upload-input"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <div className={classes.input}>
              <TextField
                value={amount}
                onChange={e => evaluate(e.target.value)}
                label="Amount"
                variant="outlined"
                
                helperText="Enter a minimum invoice amount"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </div>
            {loading ? 
                <div className={classes.root}> 
                    <RotateLoader color={"#61dafb"} size={24} margin={12}/>
                </div>
                : null
            } 
            {_.map(customers, (customer, index) => {
                const filteredInvoices = _.filter(invoices, i => (i[5] === customer));
                return (
                    <List key={customer}>
                        { invoices.length > 0 && customers.length > 0 && !loading ? 
                            <MyDocument list={_.sortBy(filteredInvoices, i => (Date.parse(i[2])))} customer={customer}/>
                            : null
                        }
                    </List>
                );
            })}
        </div>
    );
};

export default Aging;

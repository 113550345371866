import { flow } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';

import { signIn } from 'actions';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class SignIn extends React.Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = e=>{
        this.setState({[e.target.name]: e.target.value})
    }
    handleSubmit = e=>{
        e.preventDefault();
        this.props.signIn(this.state, ({type, res})=>{
            switch(type) {
                case 'Error':
                    this.props.enqueueSnackbar(res + '', {variant: 'error'})
                    break;

                case 'User':
                    this.reDirect();
                    break;
                default:
                  break;
            }
        })
    }
    reDirect() {
        if(this.props.path) {
            this.props.history.push(this.props.path)
        } else {
            this.props.history.push("/aging")
        }
    }
    validate = e=>{
        const {name, value} = e.target;
        // eslint-disable-next-line
        const validEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        switch(name) {
            case 'email':
                if(!value.match(validEmail)) {
                    this.setState(prevState=>({errors: {
                        ...prevState.errors,
                        [name]: 'Email format invaild'
                    }}))
                } else {
                    this.setState(prevState=>({errors: {
                        ...prevState.errors,
                        [name]: ''
                    }}))
                }
                break;
              default:
                break;
        }
    }
    render() {
      const { classes } = this.props;

      return (
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Gulf-Pro AR Module
            </Typography>
            <form
                className={classes.form}
                onSubmit={e=>this.handleSubmit(e)}
            >
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.email}
                    onChange={e=>this.handleChange(e)}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={e=>this.handleChange(e)}
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </main>
      );
    }
}


export default flow([
  withSnackbar,
  withRouter,
  connect(null, { signIn }),
  withStyles(styles),
])(SignIn);

